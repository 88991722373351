<template>
  <div style="width: 100%;height: 100%">
    <iframe :src="src" class="link-iframe"></iframe>
  </div>
</template>

<script>
  export default {
    name: 'IFrame',
    data() {
      return {
        src: '',
      }
    },
    computed: {},
    mounted() {
      this.src = this.$route.query.src;
    }
  }
</script>

<style lang="scss" scoped>
  .link-iframe {
    width: 100%;
    height: 100%;
    border: none;
    background-color: #fff;
  }
</style>
