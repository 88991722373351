<template>
  <div ref="refNavMenu" class="nav-bar" :style="{'background-color': theme}">
    <div class="left-menu">
    </div>
    <div ref="refPersonal" class="right-menu">
      <template v-if="device!=='mobile'">
        <Kefu id="kefu" class="menu pr-10 pl-10" v-if="useKefu"/>
        <Msg id="msg" class="menu pr-10 pl-10" v-if="useMsg"/>
      </template>
      <a-dropdown class="user-container">
        <div class="user-wrapper menu">
          <img :src="avatar" class="user-avatar">
          <div class="profile pr-10 pl-10">{{name}}</div>
        </div>
        <a-menu slot="overlay">
          <a-menu-item class="nav-menu-item">
            <router-link to="/user/profile" v-if="true">
              <a-icon type="user"/>
              <span style="margin-left: 5px">账号信息</span>
            </router-link>
          </a-menu-item>
          <a-menu-item class="nav-menu-item">
            <span @click="handleForgetPwd"><a-icon type="lock"/>忘记密码</span>
          </a-menu-item>
          <a-menu-item class="nav-menu-item">
            <span @click="handleLogout"><a-icon type="logout"/>退出登录</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>

    <!-- 修改密码dialog -->
    <a-modal v-model="showModifyPwd"
             width="773px"
             :bodyStyle="{'height': '542px'}"
             :footer="null"
             :keyboard="false"
             :maskClosable="false"
             :destroyOnClose="true">
      <PwdForget @handleOk="handleModifyPwdCallback"/>
    </a-modal>

  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Kefu from '@/components/Kefu'
  import Msg from '@/components/Msg'
  import PwdForget from "@/views/login/pwd-forget";

  export default {
    components: {Kefu, Msg, PwdForget},
    data() {
      return {
        useKefu: true,
        useMsg: true,
        showModifyPwd: false, // 修改密码
      }
    },
    computed: {
      ...mapGetters([
        'name',
        'sidebar',
        'device'
      ]),
      theme() {
        return this.$store.state.app.theme;
      },
      avatar() {
        return this.convertToPrefixPath(this.$store.getters.avatar)
      },
    },
    methods: {
      toggleSideBar() {
        this.$store.dispatch('doToggleSideBar')
      },

      /**
       * 忘记密码
       */
      handleForgetPwd() {
        this.showModifyPwd = true;
        console.log("修改密码dialog")
      },
      /**
       * 修改密码成功后
       */
      handleModifyPwdCallback() {
        this.showModifyPwd = false;
        console.log("修改成功，是否清除login信息？")
      },

      /**
       * 退出登录
       */
      handleLogout() {
        let that = this;
        this.$modal.confirm('确定要退出系统吗？').then(() => {
          that.$store.dispatch('doLogout').then(() => {
            location.href = '/login';
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nav-bar {
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-between;

    .menu {
      cursor: pointer;

      &:hover {
        // 二者一样
        /*background-color: #810100!important;*/
        background: rgba(0, 0, 0, 0.05)
      }
    }

    .left-menu {
      display: flex;
      align-items: center;

      .hamburger {
        width: 50px;
        height: 100%;
      }
    }

    .right-menu {
      display: flex;
      justify-content: right;
      align-items: center;

      .user-container {
        height: 100%;
        position: relative;

        .nav-menu-item {
        }
      }

      .user-wrapper {
        height: 100%;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .user-avatar {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }

        .profile {
          overflow: hidden;
          padding: 0 0 0 10px;
        }
      }
    }
  }
</style>
