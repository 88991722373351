import * as OssClient from "@/components/AliOss/OssClient";
import * as util from '@/utils/util'

/**
 * 把接口返回结果对象转换成upload组件用的对象结构
 * @param dbFileResult api返回结构 {uri,sort:type, type, appletComprImage, webComprImage,id}
 * @return upload组件文件对象
 */
export function convertDbToUpload(dbFileResult) {
  let uploadFileObj = {
    uid: dbFileResult.uri, // upload组件记录唯一区分
    url: OssClient.ossUrl(dbFileResult.uri),
    status: 'done',
    name: dbFileResult.fileName || dbFileResult.originalName || dbFileResult.uri,   // 文件名

    uri: dbFileResult.uri,
    fileName: dbFileResult.fileName || dbFileResult.originalName || dbFileResult.uri,   // 文件名
    tempType: dbFileResult.type,// 临时转换存储用
    type: (dbFileResult.type == '2') ? "video/*" : '', // 分类 0其他1文本2视频3图片
    id: dbFileResult.id, // 修改时候的内容id
    flag: 3, // db获取出来的默认是3 // flag :操作标记(1:新增 2:删除 3:无需处理)
  }
  return uploadFileObj;
}

/**
 * 把oss上传的结果对象转换成upload组件用的对象结构
 * @param ossFileResult oss返回经过处理后结构 {fileName,path(相对路径)}
 */
export function setVideoDataToUpload(form, ossFileResult) {
  form.fileName = ossFileResult.fileName
  form.videoUrl = OssClient.ossUrl(ossFileResult.videoPath)
  form.shortVideoUrl = OssClient.ossUrl(ossFileResult.shortVideoPath)
  form.coverUri = ossFileResult.coverPath
  form.publicityVideo = ossFileResult.shortVideoPath
  form.uploadFileList = form.uploadFileList || []
  let fileObj = {
    uid: ossFileResult.videoPath, // upload组件记录唯一区分
    url: OssClient.ossUrl(ossFileResult.videoPath),
    status: 'done',
    name: ossFileResult.fileName, // 上传时候传入的fileName

    fileName: ossFileResult.fileName,
    uri: ossFileResult.videoPath, // 上传之后的url的相对路径
    sort: 0, // 排序字段(默认就是数字下标排序)
    id: null, // id(动态：内容id是1对多),
    flag: 1,  // flag :操作标记(1:新增 2:删除 3:无需处理)
  }
  form.uploadFileList[0] = fileObj
}

/**
 * 把oss上传的结果对象转换成upload组件用的对象结构
 * @param ossFileResult oss返回经过处理后结构 {fileName,path(相对路径)}
 */
export function convertOssToUpload(ossFileResult) {
  let uploadResult = {
    uid: ossFileResult.path, // upload组件记录唯一区分
    url: ossFileResult.path,
    status: 'done',
    name: ossFileResult.fileName, // 上传时候传入的fileName

    fileName: ossFileResult.name,
    uri: ossFileResult.path, // 上传之后的url的相对路径
    sort: 0, // 排序字段(默认就是数字下标排序)
    id: null, // id(动态：内容id是1对多),
    flag: 1,  // flag :操作标记(1:新增 2:删除 3:无需处理)
  }
  return uploadResult
}

/**
 * 把upload组件用的对象结构转换成db结果对象
 * @param uploadFileResult
 */
export function convertUploadToDb(uploadFileResult) {
  let dbResult = {
    id: uploadFileResult.id,
    uri: uploadFileResult.uri,
    originalName: uploadFileResult.fileName, // 上传时候传入的fileName
    fileName: uploadFileResult.fileName, // 上传时候传入的fileName
    sort: 0, // 排序字段(默认就是数字下标排序)
    flag: uploadFileResult.flag,  // flag :操作标记(1:新增 2:删除)
    type: 2, // 分类：0其他1文本2视频3图片
  }
  return dbResult
}

/**
 * load ffmpeg
 * @param progressFn
 * @return ffmpeg
 */
import {createFFmpeg, fetchFile} from '@ffmpeg/ffmpeg'

export function initFFmpeg(progressFn) {
  const ffmpeg = createFFmpeg({
    // path for ffmpeg-core.js script， from cdn or local
    // https://unpkg.com/@ffmpeg/core@0.9.0/dist/ffmpeg-core.js
    // 这里应该是支持绝对路径，默认会去访问 ​​public​​ 下面的文件
    corePath: '/static/ffmpeg/ffmpeg-core.js',
    log: true,
    progress: progressFn,
    // logger: ({ message }) => console.log("logger", message)
  })
  ffmpeg.load()
  console.log('-----初始化FFmpeg-----')
  return ffmpeg
}


/**
 * 加载视频
 * @param ffmpeg
 * @param file
 */
export async function loadVideoFile(ffmpeg, file) {
  // 2.对视频截取15s短视频，并上传oss，获取url地址
  let originalFileName = encodeURI(file.name)

  console.log("[ffmpeg]1.载入文件=====>" + originalFileName)
  let loadResult = await fetchFile(file)

  // 对于 ffmpeg.wasm 的输入/输出文件，需要先将它们保存到 MEMFS 以便 ffmpeg.wasm 能够使用它们
  await ffmpeg.FS('writeFile', originalFileName, loadResult);
  console.log("[ffmpeg]2.载入文件完成", loadResult)

}


/**
 * 释放视频文件
 * @param ffmpeg
 * @param file
 */
export async function freeVideoFile(ffmpeg, file) {
  // 2.对视频截取15s短视频，并上传oss，获取url地址
  let originalFileName = encodeURI(file.name)

  // 释放原始文件
  await ffmpeg.FS('unlink', originalFileName);
}


/**
 * 处理短视频
 * @param ffmpeg
 * @param file
 * @return blob
 */
export async function handleVideoCrop(ffmpeg, file) {
  console.log("///////////////////////////// 处理短视频转换 /////////////////////////////////")
  let originalFileName = encodeURI(file.name)
  let newFileName = encodeURI(`${file.name.split('.')[0]}_new.mp4`)
  // console.log("[ffmpeg]开始转换处理......")
  // await this.ffmpeg.run('-i', filePath, '-acodec', 'aac', '-vcodec', 'libx264', '-y', newFileName);
  // console.log("[ffmpeg]转换处理完成")

  console.log("[ffmpeg]1.开始短视频处理......")
  await ffmpeg.run('-ss', '0', '-t', '15', '-i', originalFileName, '-f', 'mp4', '-vcodec', 'copy', '-acodec', 'copy', '-y', newFileName);
  console.log("[ffmpeg]2.转换短视频完成")

  // 在内存中读取文件
  let data = await ffmpeg.FS('readFile', newFileName);
  await ffmpeg.FS('unlink', newFileName);
  console.log("[ffmpeg]3. readFile完成")

  // 获取内存中的播放地址
  return new Blob([data.buffer], {type: 'video/mp4'})
}

/**
 * 处理视频封面
 * @param ffmpeg
 * @param file
 */
export async function handleVideoCover(ffmpeg, file) {
  console.log("///////////////////////////// 处理封面图 /////////////////////////////////")
  let originalFileName = encodeURI(file.name)
  console.log("[ffmpeg]1.开始处理封面......")
  // ffmpeg.run('-i',`${原视频}`,'-y','-f','image2','-ss','时间','-t','0.001','新命名.png');
  let coverFile = 'cover.png';
  let r = await ffmpeg.run('-i', originalFileName, '-f', 'image2', '-ss', '0', '-t', '0.001', '-y', coverFile)
  console.log("[ffmpeg]2.封面处理完成：" + coverFile, r)

  let coverData = await ffmpeg.FS('readFile', coverFile);
  await ffmpeg.FS('unlink', coverFile);
  console.log("[ffmpeg]3.封面readFile完成")
  return new Blob([coverData.buffer], {type: 'image/png'})
}


/**
 * OSS截取视频封面
 *
 * @param videoUrl 视频地址
 * @param opts 秒 {time, width, height}
 */
export function handleCoverByOss(videoUrl, opts) {
  let frameImgName = "cover.png"
  //,w_750,h_750
  let w = opts.width ? ',w_' + opts.width : ''
  let h = opts.height ? ',h_' + opts.height : ''
  let firstFrame = `${videoUrl}?x-oss-process=video/snapshot,t_${opts.time},m_fast${w}${h}`
  return new Promise((resolve, reject) => {
    return resolve(OssClient.imgUrlToFile(firstFrame, frameImgName))
  }).then(localFile => {
    return OssClient.uploadFile({
      bizType: 'video',
      type: 'file',
      fileName: localFile.name,
      file: localFile
    })
  })
}

/**
 * 视频的上传和处理
 * 1.上传原视频
 * 2.载入视频
 * 3.裁剪封面
 * 4.上传封面
 * 5.裁剪短视频
 * 6.上传短视频
 * 7.释放视频资源
 * 8.返回上传的链接结果
 *
 * @param ffmpeg
 * @param file
 */
export function handleVideo11(ffmpeg, file) {
  return new Promise((resolve, reject) => {
    let fileName = file.name
    let fileShortName = util.extName(fileName)[0]
    let videoResult = {}
    let coverResult = {}
    let shortVideoResult = {}
    console.log("【发视频】1.上传视频开始" + file)
    OssClient.uploadFile({
      bizType: 'video',
      type: 'file',
      fileName: fileName,
      file: file
    }).then(result => {
      videoResult = result
      console.log("【发视频】2.获取封面图开始...")
      // 1.截取视频首帧并保存，并上传oss，后去url地址
      let firstImgName = fileShortName + ".png"
      let firstFrame = videoResult.url + "?x-oss-process=video/snapshot,t_1000,m_fast"//,w_750,h_750
      return OssClient.imgUrlToFile(firstFrame, firstImgName)
    }).then(localFile => {
      this.uploadingText = '开始上传封面图'
      console.log("【发视频】2.上传封面图开始...")
      return OssClient.uploadFile({
        bizType: 'video',
        type: 'file',
        fileName: localFile.name,
        file: localFile
      })
    }).then(result => {
      coverResult = result
      console.log("【发视频】3.上传短视频开始...")
      // 2.对视频截取15s短视频，并上传oss，获取url地址
      // TODO
      let shortVideoResult = {}
      return new Promise((resolve) => {
        resolve(shortVideoResult)
      })
    }).then(result => {
      console.log("【发视频】视频处理完成")
      this.uploadingText = "【发视频】完成"
      let jsonData = {
        mode: 1,// 新增
        videoPath: videoResult.path,
        fileName: videoResult.name,
        coverPath: coverResult.path,
        shortVideoPath: shortVideoResult.path
      };
      return resolve(jsonData)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 视频的上传和处理
 * 1.上传原视频
 * 2.载入视频
 * 3.裁剪封面
 * 4.上传封面
 * 5.裁剪短视频
 * 6.上传短视频
 * 7.释放视频资源
 * 8.返回上传的链接结果
 *
 * @param ffmpeg
 * @param file
 */
export function handleVideo(ffmpeg, file) {
  return new Promise((resolve, reject) => {
    let fileName = file.name
    let fileShortName = util.extName(fileName)[0]
    let videoResult = {}
    let coverResult = {}
    let shortVideoResult = {}
    console.log("【发视频】1.上传视频开始" + file)
    OssClient.uploadFile({
      bizType: 'video',
      type: 'file',
      fileName: fileName,
      file: file
    }).then(result => {
      videoResult = result
      console.log("【发视频】1.上传视频完成...", videoResult)
      // 加载资源
      return loadVideoFile(ffmpeg, file)
    }).then(() => {
      console.log("【发视频】2.载入视频完成")
      // 截取封面
      return handleVideoCover(ffmpeg, file)
    }).then((coverBlob) => {
      // 上传封面
      console.log("【发视频】3.获取封面图片完成")
      // this.coverUri = URL.createObjectURL(coverBlob)
      // console.log("【发视频】[ffmpeg]封面处理完成，结果文件=====>" + this.coverUri)
      this.uploadingText = "开始上传封面"
      let coverFileName = fileShortName + ".png"
      return OssClient.uploadFile({
        bizType: 'image',
        type: 'blob',
        fileName: coverFileName,
        file: coverBlob
      })
    }).then(result => {
      coverResult = result
      console.log("【发视频】2.上传视频封面完成", coverResult)
      // 截取短视频
      return handleVideoCrop(ffmpeg, file)
    }).then((videoBlob) => {
      console.log("【发视频】3.获取短视频完成")
      // 上传短视频
      // let videoFileUrl = URL.createObjectURL(videoBlob)
      // console.log("【发视频】[ffmpeg]处理完成，结果文件=====>" + videoFileUrl)
      // this.videoUrl = videoFileUrl
      // this.$refs['refVideo'].src = this.videoUrl
      this.uploadingText = "开始上传短视频"
      let shortVideoFileName = fileShortName + ".mp4"
      return OssClient.uploadFile({
        bizType: 'video',
        type: 'blob',
        fileName: shortVideoFileName,
        file: videoBlob
      })
    }).then((result) => {
      shortVideoResult = result
      console.log("【发视频】3.上传短视频完成", shortVideoResult)
      // 释放资源
      return freeVideoFile(ffmpeg, file)
    }).then(() => {
      console.log("【发视频】视频处理完成")
      this.uploadingText = "【发视频】完成"
      let jsonData = {
        mode: 1,// 新增
        videoPath: videoResult.path,
        fileName: videoResult.name,
        coverPath: coverResult.path,
        shortVideoPath: shortVideoResult.path
      };
      return resolve(jsonData)
    }).catch(err => {
      reject(err)
    })
  })
}
