<template>
  <div class="nav-menu" @click="goto">
    <a-tooltip title="消息" placement="bottom">
      <!--      <a-icon type="bell"/>-->
      <a-badge :dot="hasMsg > 0">
        <svg-icon icon="icon-msg" class="nav-icon"/>
      </a-badge>
    </a-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'Msg',
    data() {
      return {
        url: '',
        hasMsg: 0, // 是否有消息
      }
    },
    methods: {
      goto() {
        this.$modal.alert('暂无消息')
      }
    }
  }
</script>

<style scoped lang="scss">
  .nav-menu {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 20px;
      font-weight: 600
    }

    .nav-icon {
      width: 25px;
      height: 25px;
      overflow: hidden;
    }
  }
</style>
