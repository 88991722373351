const menuRouteList = [
  //内容
  // {
  //   id: 200, parentId: 0, name: "Content", path: "/content", component: "Layout", hidden: false,
  //   meta: {menuType: 'M', title: "内容", icon: "menu-content", cache: false, link: false},
  // },
  // {
  //   id: 20020, parentId: 200, name: "VideoPublishMenu", path: "videoPublish", redirect: 'videoPublish/preface', component: "ParentView",
  //   hidden: false, meta: {menuType: 'C', title: "发视频", cache: false, link: false},
  // },
  // {
  //   id: 2002010, parentId: 20020, name: "VideoPreface", path: "preface", hidden: false, component: "content/video/video-preface",
  //   meta: {menuType: 'V', title: "发视频", icon: "", cache: false, link: false,}
  // },
  // {
  //   id: 2002020, parentId: 20020, name: "VideoEdit", path: "edit", hidden: false, component: "content/video/video-publish",
  //   meta: {menuType: 'V', title: "发视频", icon: "", cache: false, link: false,}
  // },

  {
    id: 2002010, parentId: 20020, name: "VideoPreface", path: "/content/videoPublish/preface", hidden: true, component: "content/video/video-preface",
    meta: {menuType: 'V', title: "发视频", icon: "", cache: false, link: false,}
  },
  {
    id: 2002020, parentId: 20020, name: "VideoEdit", path: "/content/videoPublish/edit", hidden: true, component: "content/video/video-publish",
    meta: {menuType: 'V', title: "发视频", icon: "", cache: false, link: false,}
  },

]
export default menuRouteList;
