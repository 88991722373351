import {JSEncrypt} from 'jsencrypt'

let publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDAc1DzX0eUCHd3wgJOve/+n8Wc5QkUKFm4EfDynvg34H/Z3XXLGqxW6pxBBo9a9pUNZlCYsAvaPRT9FClW+ISTsotexUf7Xoro6/+50Tnh10g7DzVtN82IrNjr4h4ysXNQaYQpjLhZCULrV54dz1uow6jjX1bmXfvLZooQyR6/7wIDAQAB'
let privateKey = 'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAMBzUPNfR5QId3fCAk697/6fxZzlCRQoWbgR8PKe+Dfgf9nddcsarFbqnEEGj1r2lQ1mUJiwC9o9FP0UKVb4hJOyi17FR/teiujr/7nROeHXSDsPNW03zYis2OviHjKxc1BphCmMuFkJQutXnh3PW6jDqONfVuZd+8tmihDJHr/vAgMBAAECgYBPS3RQQrLgaLiozHRc81++XyByK+CYwKNu91166ktF4qdrIheVzvCxg0tMc6J/jVgOxFhzAR5xFnfeav85iX4+Ef9I/XRT9qHWlSh+neb3Kb9L9iNZ0HDr0bWfuTITPrDlGwtgL6HquQ33K4gsKCI5x+oMZJ7a3qfckKzP6Qjm4QJBAOLecKdpkMozdZRhBUsIsuRltILF6vRItVt529W4ElZka7VuK+BudS9wLV+m7MLfVZ2j46qt+S676FdXmCBql7ECQQDZKXvv1pwnKrhIsm+iuQJFLhs3bly2t9F2k+vkTHL/safBHe7uTMTLExpBgIS/ojgIfVcqcT5I5/kMgre0jlmfAkBm2pIYVDPgeBQGgTX1Hqx7/+AmqbQrrAsMveJ8skceA8kRKDGcPnfCWgk4JGWSs84M+hBtgRJHrclYT84YZDsBAkEAzRPSCOLAwHQlZ/GqXx8cN7yc0zITuDHLS5HRmN1hVHqvEz5zXvN8fNJjXyxBML++7rV/VYNv8GWw2dGwLoCnvQJBAJqd5h8Ab9HJlp7qU54uzGvpIcr0X643AuasNOrVg/Mq/z2xAKjI+mmj4geUI+/P6Ry8yXsGUCgX3P4weWwZBEE='

/**
 * 加密处理
 * @param text
 * @returns {string | false}
 */
export function encrypt(text) {
  let str = text
  try {
    let jsEncrypt = new JSEncrypt()
    jsEncrypt.setPublicKey(publicKey)
    str = jsEncrypt.encrypt(text)
    console.log("加密后串" + str)
  } catch (e) {
    console.log("加密失败",e)
  }
  return str + ""
}

/**
 * 解密处理
 * @param text
 * @returns {string | false}
 */
export function decrypt(text) {
  let str = text
  try {
    let jsEncrypt = new JSEncrypt()
    jsEncrypt.setPrivateKey(privateKey)
    str = jsEncrypt.decrypt(text)
    console.log("解密后串" + str)
  } catch (e) {
    console.log("解密失败",e)
  }
  return  str + ""
}